import {
  Box,
  Stack,
  Typography,
  Button,
  IconButton,
  TextField,
} from "@mui/material";
import { useRef } from "react";
import { Controller } from "react-hook-form";
import {
  DrawerHeader,
  DrawerContent,
  Drawer,
  DrawerActions,
} from "@/components/common/drawer";
import { useCreateMdMeetingForm } from "@/hooks/complianceHub/useCreateMdMeetingForm";
import TimeSelector, {
  TimeSelectorRef,
} from "../booking/timeSelector/timeSelector";
import TimeSelectorButton from "../booking/timeSelector/timeSelectorButton";
import { TimeSelectorProvider } from "../booking/timeSelector/timeSelectorContext";
import { ChevronSmallLeftIcon } from "../common/icons";
import ComplianceAutocompleteField from "./createMdMeetingForm/complianceAutocompleteFields";
import { FormActions } from "./createMdMeetingForm/formActions";

function CreateMdMeetingDrawer() {
  const {
    // Drawer state
    drawerTitle,
    isOpen,
    isEditing,
    closeDrawer,

    // Form control and validation
    control,
    errors,
    isCreateButtonEnabled,
    onSubmit,

    // Options for autocomplete fields
    providerOptions,
    mdOptions,

    // Time selector state
    isTimeSelectorOpen,
    hasSetEndTime,
    setHasSetEndTime,

    // Loading state
    isLoading,
  } = useCreateMdMeetingForm();

  const timeSelectorRef = useRef<TimeSelectorRef>(null);
  const handleCancelTimeSelector = () => timeSelectorRef.current?.cancel();
  const handleSaveTimeSelector = () => timeSelectorRef.current?.confirm();

  return (
    <Drawer open={isOpen} onClose={closeDrawer}>
      <DrawerHeader
        title={drawerTitle}
        leadingAction={
          isTimeSelectorOpen ? (
            <IconButton onClick={handleCancelTimeSelector}>
              <ChevronSmallLeftIcon />
            </IconButton>
          ) : undefined
        }
      />
      <DrawerContent>
        <Stack
          spacing={5}
          sx={{
            width: {
              xs: "100%",
              md: "375px",
            },
          }}
        >
          {isTimeSelectorOpen ? (
            <TimeSelector
              events={[]} // TODO: Add calendar events (DEV-3746)
              ref={timeSelectorRef}
              onSetEndDate={() => setHasSetEndTime(true)}
            />
          ) : (
            <>
              <Stack spacing={2.5}>
                <ProvidersAutocompleteField
                  options={providerOptions}
                  control={control}
                  errors={errors}
                />
                <MdsAutocompleteField
                  options={mdOptions}
                  control={control}
                  errors={errors}
                />
                <SelectDateAndTimeField />
                <MeetingDetailsField control={control} errors={errors} />
              </Stack>
              <FormActions
                submitButtonText={isEditing ? "Save changes" : "Create meeting"}
                onCancel={closeDrawer}
                onSubmit={onSubmit}
                isCreateButtonEnabled={isCreateButtonEnabled}
                isLoading={isLoading}
              />
            </>
          )}
        </Stack>
      </DrawerContent>
      <DrawerActions>
        {isTimeSelectorOpen && (
          <>
            <Button
              onClick={handleCancelTimeSelector}
              variant="outlined"
              sx={{ flex: 1, height: 40 }}
              size="small"
            >
              Cancel
            </Button>
            <Button
              onClick={handleSaveTimeSelector}
              disabled={!hasSetEndTime}
              variant="contained"
              sx={{ flex: 1, height: 40 }}
              size="small"
            >
              Save
            </Button>
          </>
        )}
      </DrawerActions>
    </Drawer>
  );
}
CreateMdMeetingDrawer.displayName = "CreateMdMeetingDrawer";

export default function CreateMdMeetingDrawerWithTimeSelectorProvider() {
  return (
    <TimeSelectorProvider>
      <CreateMdMeetingDrawer />
    </TimeSelectorProvider>
  );
}

function ProvidersAutocompleteField({ options, control, errors }) {
  return (
    <ComplianceAutocompleteField
      control={control}
      errors={errors}
      name="providers"
      label="Select Providers"
      options={options}
      placeholder="Search for providers..."
      required
    />
  );
}

function MdsAutocompleteField({ options, control, errors }) {
  return (
    <ComplianceAutocompleteField
      control={control}
      errors={errors}
      name="mds"
      label="Select MDs"
      options={options}
      placeholder="Search for MDs..."
      required
    />
  );
}

function SelectDateAndTimeField() {
  return (
    <Box>
      <Typography variant="labelTiny" color="text.secondary" gutterBottom>
        Select date & time{" "}
        <Typography color="error" component="span">
          *
        </Typography>
      </Typography>
      <TimeSelectorButton />
    </Box>
  );
}

function MeetingDetailsField({ control, errors }) {
  return (
    <Box>
      <Controller
        name="meetingDetails"
        control={control}
        render={({ field }) => (
          <>
            <Typography variant="labelTiny" color="text.secondary" gutterBottom>
              Meeting details
            </Typography>
            <TextField
              {...field}
              fullWidth
              size="small"
              placeholder="Enter meeting details"
              error={!!errors.meetingDetails}
              helperText={errors.meetingDetails?.message}
            />
          </>
        )}
      />
    </Box>
  );
}
