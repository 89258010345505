import { Box, Typography, TextField, Autocomplete } from "@mui/material";
import { Controller, Control, FieldErrors } from "react-hook-form";

type Option = {
  id: string;
  label: string;
};

type ComplianceAutocompleteFieldProps = {
  control: Control;
  errors: FieldErrors;
  name: string;
  label: string;
  options: Option[];
  placeholder: string;
  required?: boolean;
};

export default function ComplianceAutocompleteField({
  control,
  errors,
  name,
  label,
  options,
  placeholder,
  required = false,
}: ComplianceAutocompleteFieldProps) {
  return (
    <Box>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <>
            <Typography variant="labelTiny" color="text.secondary" gutterBottom>
              {label}{" "}
              {required && (
                <Typography color="error" component="span">
                  *
                </Typography>
              )}
            </Typography>
            <Autocomplete
              {...field}
              multiple
              options={options}
              size="small"
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  size="small"
                  placeholder={placeholder}
                  error={!!errors[name]}
                  helperText={errors[name]?.message as string}
                />
              )}
              onChange={(_, value) => field.onChange(value)}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionDisabled={(option) =>
                field.value.some((item: Option) => item.id === option.id)
              }
            />
          </>
        )}
      />
    </Box>
  );
}
